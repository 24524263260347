.Dvor {
    margin: 5px;
    display: flex;
    padding: 0;
    border: 2px solid black;
    flex-direction: column;
    flex-wrap: wrap
}
.DvorItem {
    color: red;
    overflow: hidden;
    clear: both;
    width: min-content;
}

.FamilyList {
    flex-direction: row;
    flex-wrap: wrap;
    display:flex;
    color: red;
    overflow: hidden;
    clear: both;
}

.Card {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    color: red;
    overflow: hidden;
    clear: both;
    width:min-content;
}
.FormEmptyPerson {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    color: red;
    overflow: hidden;
    clear: both;
    width: min-content;
}